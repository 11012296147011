import { Dialog } from 'components/modals';
import { useCreateCompetencyMapFromFileMutation } from '@generated/graphql';
import { useToast } from 'context/toastContext';
import { useForm } from 'react-hook-form';
import { cleanSpaceInString } from 'utils/validation/createValidation';
import { Refetch } from 'pages/CompetencyMap/list/types';
import AddCompetencyMapModalContent, { AddCompetencyMapModalForm } from './Content';

interface AddCompetencyMapModalProps {
  open: boolean;
  onClose: () => void;
  refetchList: Refetch;
}

const AddCompetencyMapModal = ({ open, onClose, refetchList }: AddCompetencyMapModalProps) => {
  const { showErrorToast, showSuccessToast } = useToast();
  const [createCompetencyMap, { loading }] = useCreateCompetencyMapFromFileMutation();

  const form = useForm<AddCompetencyMapModalForm>();
  const { handleSubmit } = form;

  const onAddCompetencyMap = async (formData: AddCompetencyMapModalForm) => {
    createCompetencyMap({
      variables: {
        input: {
          companyId: formData.company.id,
          dataFile: formData.dataFile,
          specialization: cleanSpaceInString(formData.specialization),
          title: cleanSpaceInString(formData.title),
        },
      },
    })
      .then((res) => {
        if (res.data?.createCompetencyMapFromFile.id) {
          showSuccessToast('Карт компетенций добавлена');
          refetchList();
          onClose();
        } else showErrorToast();
      })
      .catch((err) => {
        showErrorToast(err.message);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onConfirm={handleSubmit(onAddCompetencyMap)}
      title='Загрузка карт компетенций'
      confirmText='Сохранить'
      isLoading={loading}
      content={<AddCompetencyMapModalContent form={form} />}
    />
  );
};

export default AddCompetencyMapModal;
