import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import qs from 'qs';
import { FilterField, FilterInputType, INPUT_DEFAULT_VALUE } from 'layout/TableLayout/types.d';
import _ from 'lodash';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { cleanSpaceInString } from 'utils/validation/createValidation';

type FilterData = { id: string; displayName: string } | string;

const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);

  const generateFilterQuery = (filters: FilterField[]) => {
    const params = {};
    filters.forEach((filter) => {
      const filterName = filter.name;
      const filterData: FilterData = _.get(query, filterName);
      if (filterData) {
        const setParam = (newData: string | boolean | string[]) =>
          _.set(params, filterName, newData);
        switch (filter.type) {
          case FilterInputType.AUTOCOMPLETE: {
            const defaultFilterData = filterData as { id: string; displayName: string };
            const autocompleteFilterValue = filter.customFilter
              ? filter.customFilter(filterData)
              : defaultFilterData.id;
            setParam(autocompleteFilterValue);
            break;
          }
          case FilterInputType.DATE: {
            const formattedDate = dayjs(filterData as string).format();
            setParam(formattedDate);
            break;
          }
          default: {
            setParam(filterData as string);
          }
        }
      }
    });

    return Object.keys(params).length === 0 ? undefined : params;
  };

  const handleFiltersSubmit = (data: string | object, filters: FilterField[]) => {
    const params = {};
    filters.forEach((filter: FilterField) => {
      const { type, name } = filter;
      const filterValue = _.get(data, name);
      const setParam = (newData: string | object) => {
        const cleanedValue = typeof newData === 'string' ? cleanSpaceInString(newData) : newData;
        _.set(params, name, cleanedValue);
      };
      if (!filterValue) return;
      switch (type) {
        case FilterInputType.DATE: {
          if (dayjs(filterValue).isValid()) setParam(dayjs(filterValue).format('YYYY-MM-DD'));
          break;
        }
        default: {
          setParam(filterValue);
          break;
        }
      }
    });
    setSearchParams(qs.stringify({ ...params, tab: query.tab }));
  };

  const handleReset = (filters: FilterField[], form: UseFormReturn) => {
    const { reset } = form;
    const inputValue: { [key: string]: null | string | number } = {};

    filters.forEach((filter) => {
      inputValue[filter.name] = INPUT_DEFAULT_VALUE[filter.type];
    });
    if (Object.keys(inputValue)) {
      reset(inputValue);
    }
    setSearchParams(qs.stringify({ tab: query.tab })); // Очистить все query параметры
  };
  return { handleFiltersSubmit, handleReset, query, generateFilterQuery };
};

export default useFilter;
