import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { BOOLEAN_OPTIONS } from 'constants/global';
import VALIDATION from 'utils/validation';

const USER_COMPANY_LIST_FILTERS: FilterField[] = [
  {
    type: FilterInputType.DATE,
    title: 'Дата добавления',
    name: 'createdAt',
  },
  {
    type: FilterInputType.TEXT,
    title: 'ИНН',
    name: 'inn',
    rules: VALIDATION.inn,
  },
  {
    type: FilterInputType.TEXT,
    title: 'Наименование компании',
    name: 'shortCompanyName',
    rules: VALIDATION.companyName,
  },
  {
    type: FilterInputType.TEXT,
    title: 'Должность',
    name: 'position',
    rules: VALIDATION.position,
  },
  {
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Создатель',
    name: 'isCreator',
    options: BOOLEAN_OPTIONS,
    customFilter: (value) => {
      const filterValue = value as { id: string; displayName: string };
      return filterValue.id === 'true';
    },
  },
  {
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Руководитель',
    name: 'isSupervisor',
    options: BOOLEAN_OPTIONS,
    customFilter: (value) => {
      const filterValue = value as { id: string; displayName: string };
      return filterValue.id === 'true';
    },
  },
];

export default USER_COMPANY_LIST_FILTERS;
