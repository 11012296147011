/*
eslint-disable no-useless-escape
*/

const PATTERN = {
  lastName: {
    value: /^[A-ZА-ЯЁ]+-?[A-ZА-ЯЁ]+$/i,
    message: 'Допускаются только буквы и дефис',
  },
  userName: {
    value: /^[A-ZА-ЯЁ ._`-]+$/i,
    message:
      'Допустимы только буквы (латиница или кириллица), а также символы: точка, подчеркивание, апостроф, дефис',
  },
  fullUserName: {
    value: /^[A-ZА-ЯЁ]+([ '_.-][A-ZА-ЯЁ]+)*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), а также символы: точка, подчеркивание, апостроф, дефис',
  },
  email: {
    value: /^[A-ZА-ЯЁ0-9._-]*@?[A-Z0-9.-]*$/i,
    message: 'Недопустимые символы в адресе электронной почты',
  },
  emailFull: {
    value: /^[A-ZА-ЯЁ0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Неверный формат email',
  },
  telegramUsername: {
    value: /^[A-Z0-9_]+$/i,
    message: 'Только буквы латинского алфавита, цифры и нижнее подчеркивание',
  },
  inn: {
    value: /^\d+$/,
    message: 'Допустимы только цифры',
  },
  companyName: {
    value: /^[A-ZА-ЯЁ0-9 ~!?@#$%^&*()+`'";:<>\/\\|«»_=№[\].,-]*$/i,
    message: 'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы и символы',
  },
  position: {
    value: /^[A-ZА-ЯЁ\s.,-]*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), пробелы, и символы: дефис, запятая и точка',
  },
  address: {
    value: /^[A-ZА-ЯЁ0-9\s.,/\\-]*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы, и символы: дефис, точка, запятая, слэш, обратный слэш',
  },
  competencyMapTitle: {
    value: /^[A-ZА-ЯЁ0-9 ~!?@#$%^&*()+`'";:<>\/\\|«»_=№[\].,-]*$/i,
    message: 'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы и символы',
  },
};

export default PATTERN;
