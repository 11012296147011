import { useCompanyOptionsQuery } from '@generated/graphql';
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import Form from 'components/form/Form';
import { Stack, Typography } from '@mui/material';
import { AutocompleteControl, TextFieldControl } from 'components/inputs';
import VALIDATION from 'utils/validation';
import FileUploadButton from 'components/inputs/FileUploadButton';
import { UseFormReturn } from 'react-hook-form';

export interface AddCompetencyMapModalForm {
  title: string;
  specialization: string;
  company: { id: string; displayName: string };
  dataFile: File;
}

interface AddCompetencyMapModalContentProps {
  form: UseFormReturn<AddCompetencyMapModalForm>;
}

const AddCompetencyMapModalContent = ({ form }: AddCompetencyMapModalContentProps) => {
  const { data, loading, refetch } = useCompanyOptionsQuery();

  const companyOptions = data?.companies?.data?.map((company) => ({
    id: company.id,
    displayName: `${company.inn}, ${company.shortName}`,
  }));

  const onInputValueChange = useCallback(
    debounce((event: React.SyntheticEvent, value: string) => {
      const isINN = value && !Number.isNaN(+value);
      refetch({ filter: isINN ? { inn: value } : { shortName: value } });
    }, 500),
    [],
  );

  return (
    <Form form={form}>
      <Stack spacing={2}>
        <TextFieldControl
          name='title'
          label='Название карты компетенций'
          rules={{ required: true, ...VALIDATION.competencyMapTitle }}
        />
        <TextFieldControl
          name='specialization'
          label='Специальность'
          rules={{ required: true, ...VALIDATION.competencyMapTitle }}
        />
        <AutocompleteControl
          label='Компания'
          name='company'
          options={companyOptions || []}
          loading={loading}
          onInputChange={onInputValueChange}
          rules={{ required: true }}
        />
        <Stack spacing={1}>
          <Typography>Файл карты компетенций</Typography>
          <FileUploadButton
            name='dataFile'
            accept='.json'
            buttonText='Загрузить карту'
            rules={{ required: 'Обязательное поле' }}
          />
        </Stack>
      </Stack>
    </Form>
  );
};

export default AddCompetencyMapModalContent;
