import { useCompanyOptionsQuery, useCopyCompetencyMapMutation } from '@generated/graphql';
import { useToast } from 'context/toastContext';
import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Form from 'components/form/Form';
import { AutocompleteControl, TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';
import VALIDATION from 'utils/validation';

interface CopyCompetencyMapModalProps {
  isOpen: boolean;
  onClose: () => void;
  competencyMapId: string;
  competencyMapTitle: string;
  refetchCompetencyMapList: (variables?: object) => Promise<ApolloQueryResult<object>>;
  company: {
    id: string;
    shortName: string;
  };
}

interface CopyCompetencyMapModalForm {
  company: { id: string; shortName: string };
  title: string;
}

const CopyCompetencyMapModal = ({
  isOpen,
  competencyMapTitle,
  competencyMapId,
  onClose,
  refetchCompetencyMapList,
  company,
}: CopyCompetencyMapModalProps) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const onError = () => showErrorToast('Произошла ошибка');

  const form = useForm<CopyCompetencyMapModalForm>({
    defaultValues: {
      title: competencyMapTitle,
      company,
    },
  });
  const { handleSubmit } = form;

  const [copyMap, { loading: copyLoading }] = useCopyCompetencyMapMutation();

  const onSubmit = (formData: CopyCompetencyMapModalForm) =>
    copyMap({
      variables: {
        input: {
          competencyMapId,
          title: formData.title?.trim() ? formData.title : undefined,
          newCompanyId: formData.company?.id,
        },
      },
    })
      .then((res) => {
        const newMap = res.data?.copyCompetencyMap;
        if (newMap) {
          showSuccessToast('Копия карты компетенций создана');
          refetchCompetencyMapList();
          onClose();
        } else onError();
      })
      .catch(() => onError());

  const { data, loading: companyOptionsLoading, refetch } = useCompanyOptionsQuery();
  const companyOptions = data?.companies?.data;

  const onInputValueChange = useCallback(
    debounce(
      (event: React.SyntheticEvent, value: string) => refetch({ filter: { shortName: value } }),
      500,
    ),
    [],
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle textAlign='center'>Копировать карту компетенций</DialogTitle>
      <Form form={form}>
        <Stack spacing={4}>
          <Typography>
            {`Вы уверены, что хотите создать копию карты компетенций ${competencyMapTitle}`}
          </Typography>
          <TextFieldControl
            name='title'
            label='Название новой карты'
            rules={VALIDATION.competencyMapTitle}
          />
          <AutocompleteControl<CopyCompetencyMapModalForm['company']>
            loading={companyOptionsLoading}
            name='company'
            options={companyOptions || []}
            label='Копировать в компанию'
            onInputChange={onInputValueChange}
            getOptionLabel={(option) => (typeof option === 'object' ? option.shortName : option)}
          />
        </Stack>
      </Form>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Отменить
        </Button>
        <LoadingButton
          loading={copyLoading}
          onClick={handleSubmit(onSubmit, onError)}
          variant='contained'>
          Копировать
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyCompetencyMapModal;
