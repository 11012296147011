import { Stack } from '@mui/material';
import Form from 'components/form/Form';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useToast } from 'context/toastContext';
import { TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import { useUpdateUserMutation, useUserDataQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { CircularLoading } from 'components/common';
import VALIDATION from 'utils/validation';

interface PersonalDataForm {
  firstName: string;
  lastName: string;
  middleName: string | undefined;
  email: string;
}

const PersonalData = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { userId } = useParams<{ userId: string }>();
  const { data, loading } = useUserDataQuery({
    variables: {
      userId: userId!,
    },
  });

  const user = data?.user;

  const form = useForm<PersonalDataForm>({
    values: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      middleName: user?.middleName || '',
      email: user?.userEmail.email || '',
    },
  });
  const { handleSubmit } = form;

  const userNameValidationRules = (isRequired: boolean = false): RegisterOptions => ({
    required: isRequired,
    ...VALIDATION.fullUserName,
  });

  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation();

  const onError = () => {
    showErrorToast('Проверьте правильность заполнения формы');
  };

  const onSubmit = (formData: PersonalDataForm) => {
    updateUser({
      variables: {
        input: {
          id: user!.id,
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          middleName: formData.middleName?.trim(),
          email: formData.email.trim(),
        },
      },
    })
      .then(() => showSuccessToast('Данные успешно сохранены'))
      .catch(onError);
  };

  if (loading) return <CircularLoading />;

  return (
    <Form form={form}>
      <Stack spacing={4} sx={{ width: { xs: '100%', md: '50%' } }}>
        <TextFieldControl name='lastName' label='Фамилия' rules={userNameValidationRules(true)} />
        <TextFieldControl name='firstName' label='Имя' rules={userNameValidationRules(true)} />
        <TextFieldControl
          name='middleName'
          label='Отчество'
          rules={userNameValidationRules(false)}
        />
        <TextFieldControl
          name='email'
          label='Email'
          rules={{
            required: true,
            ...VALIDATION.emailFull,
          }}
        />
      </Stack>
      <LoadingButton
        sx={{ mt: 4 }}
        loading={updateUserLoading}
        onClick={handleSubmit(onSubmit, onError)}
        variant='contained'>
        Сохранить
      </LoadingButton>
    </Form>
  );
};

export default PersonalData;
